import React, {Component} from 'react'
import { DirectoryOfServicesLayout } from '../../../components/layouts'
import { withPreview,  } from '../../../components/common'
import { PreviewHelper } from '../../../helpers'
class DirectoryOfServicesPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: null,
            sitemap: []
        }
    }
    componentDidMount() {
        this.props.getPowerToProtectSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getPowerToProtectEntry('pageDirectoryOfServicesPTP', this.props.location.search).then(entry => {
            this.initializeDirectoryOfServicesPage(entry)
        })
    }
    initializeDirectoryOfServicesPage(entry) {
        const links = PreviewHelper.parseLinkList(entry.fields.links)
        const page = {
            __typename: 'ContentfulPageDirectoryOfServicesPtp',
            ...entry.fields,
            links
            
        }
        this.setState({
            page,
        })
    }
    render() {
        const { page, sitemap } = this.state
        const site = this.props.getSiteInfo()
        return (!this.props.loading && page && <DirectoryOfServicesLayout className="ptp-root" site={site} page={page} location={this.props.location} preview={true} sitemap={sitemap}/>)
    }
}
export default withPreview(DirectoryOfServicesPreview)